// Import necessary constants and modules
import {
  sortingTypes,
  sortingOrder,
  filesViewTypes,
} from "/WebInterface/new-ui/assets/js/constants.js";
import State from "./state.js";
import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";

// Define the initial state of the store
const initialState = {
  initFiles: [],
  files: [],
  userInfo: {},
  currentDirectoryInfo: {},
  copiedItems: {},
  pastedItems: [],
  basketDetails: {
    initFiles: [],
    files: [],
    keepDirectoryOnTop: false,
    activeView: filesViewTypes.List,
  },
  searchFilterDetails: {
    isSearchFilterActive: false,
    hasOpened: false,
  },
  userOptions: {
    preference: {},
    changeYourPassword: {},
    phoneNumber: {},
  },
  userCustomizations: {
    OPEN_NEW_WINDOW_EXTENSIONS: [],
  },
  downloadFilesDetails: {
    files: {},
  },
  chunkProcessingStatus: {
    isUploading: false,
    isDownloading: false,
    isFilesOnUploadQueue: false,
    isFilesOnDownloadQueue: false,
  },
  expandAllAndCollapseAllDetails: {
    allDashboardListingItemsExpanded: false,
    expandingOrCollapsingInProcess: false,
  },
  shareDetails: {},
  customUploadForm: null,
  customCommonUploadForm: {
    formDetails: null,
  },
  subscribedEvents: [],
};

// Helper function to get the number of days based on the select value
const getNumberOfDays = (selectValue) => {
  switch (selectValue) {
    case "checkToday":
      return 1;
    case "checkWeek":
      return 7;
    case "checkMonth":
      return 30;
    case "checkLastTwoMonth":
      return 60;
    case "checkLastThreeMonth":
      return 90;
    default:
      return 0;
  }
};

// Create a new instance of the State class with initial state and empty actions, subscriptions, and history
const store = new State({ actions: {}, subscriptions: [], history: [] });

// Initialize the store with the initial state
Object.keys(initialState).forEach((key) => {
  if (!(key in store)) {
    store[key] = initialState[key];
  }
});

store.setCustomUploadForm = (data = { payload: {} }) => {
  store.customUploadForm = data.payload;
};

store.setCustomCommonUploadForm = (data = { payload: {} }) => {
  store.customCommonUploadForm.formDetails = data.payload;
};
// Define action functions to update the store's state

store.setUserOptions = (data = { payload: {} }) => {
  store.userOptions = utils.deepCopy(data.payload);
};

store.setSearchFilterDetails = (data = {}) => {
  store.searchFilterDetails = data.payload;
};

store.setFileListing = (data = { payload: [] }) => {
  // Get user options from local storage or use an empty object
  const userOptions = JSON.parse(localStorage.getItem("userOptions")) || {};

  // Filter out dot items if the hideDotItems option is enabled
  const filteredDotItems = userOptions.hideDotItems
    ? data.payload.filter((file) => file.name.charAt(0) !== ".")
    : data.payload;

  store.initFiles = filteredDotItems;

  // Sort the files based on user options
  const sortedFiles = utils.checkOptionsAndSort(
    utils.deepCopy(filteredDotItems)
  );

  store.files = sortedFiles;
};

// Set the file listing on sort in the store
store.setFileListingOnSort = (data = { payload: [] }) => {
  const { files } = store;
  const sortedFiles = utils.checkOptionsAndSort(files);

  store.files = sortedFiles;
};

// Update the file listing in the store
store.updateFileListing = (data = { payload: [] }) => {
  const files = utils.deepCopy(data.payload);

  store.files = files;
  store.initFiles = files;
};

// Handle checking/unchecking a file in the store
store.handleCheckFile = (data = {}) => {
  const { isChecked, selectedFile } = data.payload;

  const _files = store.files.map((file) => {
    if (selectedFile.root_dir === file.href_path + "/") {
      if (isChecked) {
        const hasAnUncheckedChildren = store.files.find(
          (item) =>
            item.root_dir === file.href_path + "/" &&
            !item.isChecked &&
            item !== selectedFile
        );

        if (!hasAnUncheckedChildren) {
          return { ...file, isChecked };
        }
      } else {
        return { ...file, isChecked };
      }
    }

    if (file.id === selectedFile.id || file.treeIds.includes(selectedFile.id)) {
      return { ...file, isChecked };
    }

    return file;
  });

  store.files = _files;
};

// Handle toggling files from IDs in the store
store.handleToggleFileFromIDs = (data = { payload: [] }) => {
  const fileIds = data.payload;

  if (fileIds && Object.keys(fileIds).length > 0) {
    const { files } = store;
    const _files = files.map((file) => ({
      ...file,
      isChecked: fileIds[file.id] || false,
    }));

    store.files = _files;
    store.initFiles = _files;
  }
};

// Set the copied items in the store
store.setCopiedItems = (data = {}) => {
  store.copiedItems = {
    copy_id: data.payload.copy_id,
    type: data.payload.type,
    items: utils.deepCopy(data.payload.selectedPaths),
    names: utils.deepCopy(data.payload.selectedNames),
  };
};

// Handle the paste operation in progress in the store
store.pasteInProgress = (data = {}) => {
  const { action, id, ...payload } = data.payload;
  if (action === "add") {
    const item = { id, ...payload };
    store.pastedItems.push(item);
  } else if (action === "remove") {
    store.pastedItems = store.pastedItems.filter((item) => item.id !== id);
  } else if (action === "update") {
    const itemIndex = store.pastedItems.findIndex((item) => item.id == id);
    const curItem = store.pastedItems[itemIndex];
    if (curItem) {
      for (const key in payload) {
        curItem[key] = payload[key];
      }
    }
  }
};

// Set the current directory info in the store
store.setCurrentDirInfo = (data = { payload: [] }) => {
  const buttons_subitem = store?.userInfo?.buttons?.buttons_subitem || [];
  const currentPerms = data.payload.privs;

  const buttons = buttons_subitem.map((btnInfo) => {
    if (btnInfo.key === "(cut):Cut") {
      const noDeleteAndDeleteDirPermission =
        !currentPerms?.includes("(delete)") &&
        !currentPerms?.includes("(deletedir)");

      if (noDeleteAndDeleteDirPermission) {
        return { ...btnInfo, for_menu: "false" };
      }
    }

    if (
      btnInfo.key === "(login):Login" &&
      utils.storage.get("loggedUsername") !== "anonymous"
    ) {
      return { ...btnInfo, for_menu: "false", for_context_menu: "false" };
    }

    return btnInfo;
  });

  store.currentDirectoryInfo = utils.deepCopy(data.payload);
  store.userInfo.buttons.buttons_subitem = utils.deepCopy(buttons);
};

// Set the user info in the store
store.setUserInfo = (data = { payload: [] }) => {
  store.userInfo = utils.deepCopy(data.payload);
};

// Add files to the basket in the store
store.addFilesToBasket = (data = { payload: [] }) => {
  const files = utils.deepCopy(data.payload);

  const newFiles = [...store.basketDetails.files, ...files];

  store.basketDetails.files = newFiles;
  store.basketDetails.initFiles = newFiles;
};

// Set the files in the basket in the store
store.setFilesInBasket = (data = { payload: [] }) => {
  const files = utils.deepCopy(data.payload);

  store.basketDetails.initFiles = files;
  store.basketDetails.files = files;
};

// Set the basket items on sort in the store
store.setBasketItemsOnSort = (data = {}) => {
  const { action } = data.payload;
  const { basketDetails } = store;

  const sortingType =
    basketDetails[action] === sortingOrder.ASC
      ? sortingOrder.DES
      : sortingOrder.ASC;

  const sortedBasketItems = utils.sortListing({
    payload: {
      fieldName: action,
      order: sortingType,
      type: action === "modified" ? sortingTypes.DATE : sortingTypes.STRING,
      files: utils.deepCopy(basketDetails.files),
    },
  });

  store.basketDetails = {
    ...basketDetails,
    [action]: sortingType,
    active: action,
    files: basketDetails.keepDirectoryOnTop
      ? utils.keepDirectoryOnTop(sortedBasketItems)
      : sortedBasketItems,
  };
};

// Set the basket items on directory sort in the store
store.setBasketItemsOnDirectorySort = () => {
  const { basketDetails } = store;

  store.basketDetails = {
    ...basketDetails,
    files: !basketDetails.keepDirectoryOnTop
      ? utils.keepDirectoryOnTop(basketDetails.files)
      : basketDetails.files,
    keepDirectoryOnTop: !basketDetails.keepDirectoryOnTop,
  };
};

// Handle checking/unchecking a file in the basket in the store
store.handleCheckFileBasket = (data = {}) => {
  const { basketDetails } = store;
  const { isChecked, selectedFile } = data.payload;

  store.basketDetails = {
    ...basketDetails,
    files: basketDetails.files.map((file) =>
      file.id === selectedFile.id ? { ...file, isChecked } : file
    ),
  };
};

// Handle removing a file from the basket in the store
store.handleRemoveFileBasket = (data = {}) => {
  const { basketDetails } = store;
  const { selectedFileId } = data.payload;

  store.basketDetails = {
    ...basketDetails,
    files: basketDetails.files.filter((file) => file.id !== selectedFileId),
  };
};

// Handle changing the view of the basket in the store
store.handleChangeBasketView = (data = { payload: filesViewTypes.GRID }) => {
  const { payload: activeView } = data;
  const basketDetails = utils.deepCopy(store.basketDetails);
  store.basketDetails = { ...basketDetails, activeView };
};

// Handle clearing the basket in the store
store.handleClearBasket = () => {
  store.basketDetails = initialState.basketDetails;
};

// Search files by value in the store
store.searchFilesByValue = (data = { payload: "" }) => {
  const { payload: searchValue } = data;
  const initFiles = utils.deepCopy(store.initFiles);

  const files =
    searchValue.length > 0
      ? utils.filterFiles(initFiles, searchValue)
      : initFiles;
  store.files = utils.checkOptionsAndSort(files);
};

// Filter basket items by value in the store
store.filterBasketItems = (data = { payload: "" }) => {
  const { payload: searchValue } = data;
  const initFiles = utils.deepCopy(store.basketDetails.initFiles);
  store.basketDetails.files =
    searchValue.length > 0
      ? utils.filterFiles(initFiles, searchValue)
      : initFiles;
};

// Handle selecting files in the store
store.handleSelectFiles = (data = { payload: "" }) => {
  const { payload: selectValue } = data;
  const files = utils.deepCopy(store.files);
  let updatedFiles;

  switch (selectValue) {
    case "toggle":
      updatedFiles = files.map((fileInfo) => ({
        ...fileInfo,
        isChecked: !fileInfo.isChecked,
      }));
      break;
    case "checkAll":
      updatedFiles = files.map((fileInfo) => ({
        ...fileInfo,
        isChecked: true,
      }));
      break;
    case "uncheckAll":
      updatedFiles = files.map((fileInfo) => ({
        ...fileInfo,
        isChecked: false,
      }));
      break;
    case "checkAllFiles":
      updatedFiles = files.map((fileInfo) => ({
        ...fileInfo,
        isChecked: fileInfo.type === "FILE",
      }));
      break;
    case "checkAllFolders":
      updatedFiles = files.map((fileInfo) => ({
        ...fileInfo,
        isChecked: fileInfo.type === "DIR",
      }));
      break;
    case "checkItemsWithDot":
      updatedFiles = files.map((fileInfo) => ({
        ...fileInfo,
        isChecked: fileInfo.name.trim().charAt(0) === ".",
      }));
      break;
    case "checkToday":
    case "checkWeek":
    case "checkMonth":
    case "checkLastTwoMonth":
    case "checkLastThreeMonth":
      updatedFiles = utils.filterModifiedFilesByDay(
        files,
        getNumberOfDays(selectValue)
      );
      break;
    default:
      updatedFiles = files;
  }

  store.files = updatedFiles;
};

store.handleSelectFilesInRange = (data = { payload: "" }) => {
  const { startElementID, endElementID } = data.payload;

  if (!startElementID || !endElementID) {
    return;
  }

  if (startElementID === endElementID) {
    return;
  }

  const files = store.files;
  let begin = false;

  files.every((ele) => {
    if (ele.id === startElementID || ele.id === endElementID) {
      if (begin) {
        return false;
      }

      begin = true;
      return true;
    }

    if (begin) {
      ele.isChecked = true;
    }
    return true;
  });
};

// Set user customization in the store
store.setUserCustomization = (data = { payload: { type: "", value: "" } }) => {
  const { type, value } = data.payload;
  store.userCustomizations = { ...store.userCustomizations, [type]: value };
};

// Download Files Actions
store.setFilesInDownload = (data = {}) => {
  const file = data.payload;
  store.downloadFilesDetails.files = {
    ...store.downloadFilesDetails.files,
    [file.id]: file,
  };
};

store.removeAllDownloadFiles = (data) => {
  const downloadFiles = data?.payload;
  store.downloadFilesDetails.files = downloadFiles;
};

store.updateProgressBarDownload = (data = { payload: {} }) => {
  const { fileId, bytesDownloaded, bytesWritten, fileSize } = data.payload;

  const file = store.downloadFilesDetails.files[fileId];

  store.downloadFilesDetails.files[fileId] = {
    ...file,
    bytesWritten,
    progressStatus: { bytesDownloaded, fileSize },
  };
};

store.updateFileStatusDownload = (data = {}) => {
  const { fileId, updates } = data.payload;

  const file = store.downloadFilesDetails.files[fileId];

  store.downloadFilesDetails.files[fileId] = {
    ...file,
    ...updates,
  };
};

store.updateTenSecondAverageDownload = (data = { payload: {} }) => {
  const { fileId, speed } = data.payload;

  const file = store.downloadFilesDetails.files[fileId];

  store.downloadFilesDetails.files[fileId] = {
    ...file,
    timeStatus: { ...file.timeStatus, avg10SecSpeed: speed },
  };
};

store.updateTimeStatusDownload = (data = { payload: {} }) => {
  const { fileId, speed, elapsedTime, timeRemaining, bytesWritten } =
    data.payload;

  const file = store.downloadFilesDetails.files[fileId];

  store.downloadFilesDetails.files[fileId] = {
    ...file,
    bytesWritten,
    timeStatus: { speed, elapsedTime, timeRemaining },
  };
};

store.deleteFileInDownload = (data = { payload: {} }) => {
  const { id } = data.payload;
  delete store.downloadFilesDetails.files[id];
};

store.setChunkProcessingStatus = (data = { payload: {} }) => {
  const { type, status } = data.payload;
  store.chunkProcessingStatus[type] = status;
};

store.handleExpandAllDashboardListing = () => {
  store.expandAllAndCollapseAllDetails.allDashboardListingItemsExpanded = true;
  store.expandAllAndCollapseAllDetails.expandingOrCollapsingInProcess = true;
};

store.handleCollapseAllDashboardListing = () => {
  store.expandAllAndCollapseAllDetails.allDashboardListingItemsExpanded = false;
  store.expandAllAndCollapseAllDetails.expandingOrCollapsingInProcess = true;
};

// Update the file listing in the store
store.updateFileListingOnExpandOrCollapse = (data = { payload: [] }) => {
  const files = utils.checkOptionsAndSort(data.payload);
  store.files = files;

  store.expandAllAndCollapseAllDetails.expandingOrCollapsingInProcess = false;
};

store.setFileListingForUploadOnly = (data = { payload: [] }) => {
  store.files = data.payload;
};

store.setShareDetails = (data = { payload: [] }) => {
  store.shareDetails = data.payload;
};

store.setAllSubscribeEvents = (data = { payload: [] }) => {
  store.subscribedEvents = data.payload;
};

export default store;
